.GreyCard{
    display: block;
    background-color: whitesmoke;
    margin: 8px 4px;
    min-height: 150px;
    border-radius: 4px;
    padding: 18px 25px;
    justify-content: space-between;

}
.Microsoft-Heading{
    display: flex;
    justify-content: space-between;
    
}

.UsageLabel{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
}

.UsageCardContent{
    display: flex;
    justify-content: space-between;
}
.UsageComponent{
    display: block;
}
.UsageTimeStamp{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5rem; 
}