.ServiceDeskIntake div h3 {
  text-align: left;
}

.ServiceDeskIntake .rv-xy-plot {
  min-height: 13rem;
}

.ServiceDeskIntake .rv-xy-plot__inner {
  min-height: 16rem;
}

.ServiceDeskIntake .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}
