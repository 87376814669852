.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.WorkByProperty h3,
.WorkByCode h3,
.WorkByStatus h3,
.WorkByStatus section,
.WorkByDuration h3,
table tr td {
  text-align: left;
}

.WorkByDuration span {
  text-align: left;
  /* margin-bottom: 1rem; */
}

.GraphTab {
  margin-bottom: 2rem;
}

.AlignRight {
  text-align: right;
}

.MaintenanceTab {
  margin-bottom: 2rem;
}
