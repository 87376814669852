.ISCompliance div {
  text-align: left;
}

.ISCompliance h3 {
  margin-bottom: 0.5rem;
}
.last-approved
{
  float: left;
  margin-top: 1rem;
}
.expired-date
{
  float: right;
  margin-top: 1rem;
}
.font-size-2rem
{
  font-size: 1.5rem!important;
}

