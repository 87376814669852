.SocialMediaAccount h3,
.SocialMediaAccount span {
  text-align: left;
}

.SocialMediaAccount > div > span {
  margin-bottom: 1rem;
}

.MediaGrid {
  display: grid;
  grid-template-columns: auto auto;
}

.MediaGridItem {
  padding: 20px;
  text-align: center;
}

.MediaGridItem:nth-child(1) {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.MediaGridItem:nth-child(2) {
  border-bottom: 1px solid grey;
}

.MediaGridItem:nth-child(3) {
  border-right: 1px solid grey;
}

.UpArrow path {
  fill: green !important;
}

.DownArrow path {
  fill: red !important;
}

.PastMonths {
  margin-top: 1rem;
}

.RecentMonth {
  margin-top: 0.5rem;
}

.Black {
  color: #000;
}

.Green {
  color: green;
}

.Red {
  color: red;
}
