.TechnologyStandards {
  margin-top: 2rem;
}

.TechnologyStandards > div {
  text-align: left;
}

.TechnologyStandards > div > h3 {
  margin-bottom: 0.5rem;
}

.TechnologyStandards > div > span {
  margin-bottom: 1rem;
}
