.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.LPAGraph,
.TabsMetric {
  margin-bottom: 2rem;
}

.LPAGraph button {
  width: 8.5rem;
}

.GovDelivery {
  min-height: 25rem;
}

.GovDelivery .rv-xy-plot {
  min-height: 16rem;
}

.GovDelivery > div > span {
  font-weight: bold;
}

.PlatformTrends {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0.75rem;
}

.MediaStories .rv-xy-plot {
  min-height: 15.5rem;
}

.MediaStories .rv-xy-plot__inner {
  min-height: 16rem;
}

.MediaStories .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}
