.DirectPOTrendline {
  margin-bottom: 2rem;
}

.DirectPO > div,
.DirectPOSpend > div {
  text-align: left;
}

.DirectPO > div > h3,
.DirectPOSpend > div > h3 {
  margin-bottom: 0.5rem;
}

.POHeader {
  display: grid;
  grid-template-columns: 4fr 1fr 15fr;
  margin: 2rem 4rem;
}

.HeaderChart .rv-xy-plot__inner {
  min-height: 16rem;
}

.HeaderInfo {
  align-self: center;
}

.PODivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 14rem;
  margin-top: 1rem;
}

.button-align--right {
  float: right;
}