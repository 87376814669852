.OpenJob {
  margin-bottom: 1.5rem;
}

.OpenJob h3 {
  text-align: left;
}

.rdt_TableHeadRow div {
  background-color: #005c95;
  color: #ffffff;
}
