.CashReporting {
  margin-bottom: 2rem;
}

.CashReporting h3,
.CashReporting span,
.CashReporting ul {
  text-align: left;
}

.CashReporting > div > h3 {
  margin-bottom: 0.25rem;
}

.FundsTab > div > h3 {
  text-align: left;
}

.LiabilityChart .rv-xy-plot__axis__title text {
  translate: -0.25rem -4.5rem;
}
