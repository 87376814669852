.SoleSource {
  margin-bottom: 2rem;
}

.SoleSource button {
  min-width: 12rem;
}

.SoleSourcePO > div,
.SoleSourcePOSpend > div {
  text-align: left;
}

.SoleSourcePO > div > h3,
.SoleSourcePOSpend > div > h3 {
  margin-bottom: 0.5rem;
}

.button-align--right {
  float: right;
}

.HeaderInfo {
  width: fit-content;
}

.HeaderInfo H1 {
  margin: 0;
  text-align: center;
}