.WeeklyNonTravel {
  margin-bottom: 2rem;
}

.TPCount div,
.TPAmountPaid > div {
  text-align: left;
}

.TPCount > div > h3,
.TPAmountPaid > div > h3 {
  margin-bottom: 0.5rem;
}

.NonTravelInfo {
  margin-top: 2rem;
}

.TravelDivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 4rem;
  margin-top: 1rem;
}
