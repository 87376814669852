.AverageCPSolicitationTime {
  margin-bottom: 2rem;
}

.AverageCPSolicitationTime h3,
.FinancialYear {
  text-align: left;
  margin-bottom: 1.5rem;
}

table th {
  color: #ffffff;
  background-color: #005c95;
  border-color: #ffffff;
}

tbody tr td {
  text-align: right;
}

table td,
th {
  border-color: #bfd9e9;
}

.SubHeading {
  font-weight: 400;
}
