@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.OMES-At-Glance {
  margin-top: -2rem;
  float: left;
}

.No-Data-Message {
  float: left;
}

.Total-Value {
  background-color: #005c95 !important;
  color: #ffffff !important;
}

.AdjustHeight {
  display: grid;
}

.Light-Blue {
  background: #e6f0f6;
  color: #003251;
}

.rv-crosshair__item {
  display: none;
}

.ShowHint {
  background: #3a3a48;
  border-radius: 4px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  width: -webkit-max-content;
  width: max-content;
}

.TopHeader {
  display: flex;
  justify-content: space-around;
}

.HeadCounts {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
}
.non-complaince-counts {
  /* display: flex;
  flex-direction: column; */
  /* margin-bottom: 1.5rem; */
}

.Divider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 7rem;
  margin-top: 1rem;
}

.HintWrap {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.Dark-Theme .fa-secondary, .Dark-Theme .fa-primary {
  
  fill:#005c95 !important;
  opacity :revert;
  cursor: pointer !important;
}
.Main-Title
{
  float: left;
  margin-top: -2rem;
}
.Sub-Title
{
  float: left !important;
    text-align: left;
    margin-top: 0rem;
    clear:left;
}
.title-wrapper
{
  color:#005c95;
  padding-bottom: 0.5rem;
}
.title {
  margin: 1rem 0 0.5rem;
}
.Margin-Top-3
{
  margin-top: -3rem
}
.Banner-Style
{
  color: #000;
    /* background-color: rgb(168, 107, 28); */
    background-color: #F4E2D9;
    height: 2rem;
    font-style: italic;
    text-align: center;
    vertical-align: middle;
    line-height: 2rem;
}
.Banner-Style a{
  /* color: #fff !important; */
}
.Card-Margin-Top
{
  margin-top:.75rem;
}
.No-Color
{    border: none!important;
  background-color: #fff!important;
}
.Center-align{
  text-align: center;
}
.Small-Divider
{
  height: 3rem!important;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
}
.metricDivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  margin-top: 1rem;
}
.TopHeader-Tasks
{
  display: flex;
    justify-content: center;
}
.Card-Margin-Top h3{
  text-align: left;
}
.Horizontal-Divider {
  border-top: 2px solid #464646;
  margin: 2rem;
}
.Margin-Top-2rem
{
  margin-top: 2rem;
}
.Day-Subtitle
{
float:left;
margin-top:-1rem;
margin-bottom: 2rem;
}

.alt-y-label {
  -webkit-transform: translate(20px, 25px);
          transform: translate(20px, 25px);
}

.wrap-text {
  white-space: pre-wrap;
}

.justify-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin-bottom-xs {
  margin-bottom: 0.5rem;
}

.card-wrapper h3 {
  text-align: left;
}

.footer-text {
  font-weight: 700;
  line-height: 1.5;
  margin-top: 1rem;
}
.no-bullets, .no-bullets li {
  list-style-type:none;

}
.GreyCard{
    display: block;
    background-color: whitesmoke;
    margin: 8px 4px;
    min-height: 150px;
    border-radius: 4px;
    padding: 18px 25px;
    justify-content: space-between;

}
.Microsoft-Heading{
    display: flex;
    justify-content: space-between;
    
}

.UsageLabel{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
}

.UsageCardContent{
    display: flex;
    justify-content: space-between;
}
.UsageComponent{
    display: block;
}
.UsageTimeStamp{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.5rem; 
}
.OfferedTraining h3 {
  text-align: left;
}

.TrainingChart {
  display: flex;
  align-items: center;
}

.TotalHours {
  display: flex;
  /* margin-left: 2rem; */
  justify-content: center;
}

.SLSTotalHours {
  margin: 1rem 5rem;
}

.ODCSTotalHours {
  /* margin: 1rem 3rem; */
  margin-left: 2rem;
}

.SLSHours,
.ODCSHours {
  text-align: center;
  margin-top: 0.2rem;
}
.SmallTabs
{
  width: 7rem!important;
}

.MonthlyUniqueVisitsCard{
    max-width: 300px;
    min-height: 210px;
    background-color: #2D5C6C ;
    margin-bottom: 30px;
    padding: 20px 10px 30px 30px;
    border-radius: 1px;
    position: relative;
}
.MonthlyUniqueVisitsCard > span {
    top: 0.5rem;
}
.SwvNumberCard{
    background-color: #ffffff;
    width: 240px;
    min-height: 120px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
}
.SwvTitle{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  color: #FFFFFF;
  padding-bottom: 20px;
  margin-left: 20px;
}
.SwvNumber{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 36px;
   margin-top: 18px;
   margin-left: 30px;
}
.SwvNumberBlock{
    display: flex;
}
.fas{
   margin-top: 25px;
   margin-left: 5px;
}
.filterInput {
    /* width : 30% */
}
.COOPScore {
  margin-top: 2rem;
}

.COOPScore h3,
.COOPScore ol {
  text-align: left;
}

.DisasterRecovery {
  margin-top: 2rem;
}

.DisasterRecovery h3 {
  font-size: 1.25rem;
}

.DisasterRecovery h3,
.StaticText {
  text-align: left;
}

.CloudSpend h3 {
  text-align: left;
  margin-bottom: -0.25rem;
}

.CloudSpend .rv-xy-plot__inner {
  min-height: 14rem;
}

.AzureCost h3 {
  text-align: left;
}

.AzureCost .rv-xy-plot {
  min-height: 13rem;
}

.AzureCost .rv-xy-plot__inner {
  min-height: 14rem;
}

.AzureCost .rv-xy-plot__axis__title text {
  translate: -3.5rem -4.5rem;
}

.ServerData {
  margin-top: 2rem;
}

.ServerMetrics,
.ServerCloudSpend,
.AzureCost {
  margin-bottom: 2rem;
}

.EndUserCasesByMonth {
  margin-bottom: 1rem;
}

.ProvisioningResolutionTime {
  margin: 0.75rem 0rem;
}

.ProvisioningResolutionTime button {
  min-width: 15rem;
}

.ActualResTime div {
  text-align: left;
}

.ActualResTime div h3 {
  margin-bottom: 0.5rem;
}

.ActualChart {
  margin-top: 1rem;
}

.MonthlyChart .rv-xy-plot {
  min-height: 13rem;
}

.MonthlyChart .rv-xy-plot__inner {
  min-height: 14rem;
}

.MonthlyChart .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}

.csat-metric--wrapper .rv-xy-plot__inner {
    min-height: 16rem;
}

.csat-metric--card {
    padding-bottom: 2rem !important;
    margin-bottom: 0.75rem;
}
.IndividualDeploymentTotals div h3 {
  text-align: left;
}

.IndividualDeploymentTotals .rv-xy-plot {
  min-height: 13rem;
}

.IndividualDeploymentTotals .rv-xy-plot__inner {
  min-height: 16rem;
}

.IndividualDeploymentTotals .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}

.ClosedCases div h3 {
  text-align: left;
}

.ClosedCases .rv-xy-plot {
  min-height: 13rem;
}

.ClosedCases .rv-xy-plot__inner {
  min-height: 16rem;
}

.ClosedCases .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}

.EndUserCasesByPercentage div h3 {
  text-align: left;
}

.EndUserCasesByPercentage .rv-xy-plot__inner {
  min-height: 14rem;
}

.ServiceDeskIntake div h3 {
  text-align: left;
}

.ServiceDeskIntake .rv-xy-plot {
  min-height: 13rem;
}

.ServiceDeskIntake .rv-xy-plot__inner {
  min-height: 16rem;
}

.ServiceDeskIntake .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}

.TurnAroundMetric {
  width: 40%;
  margin-right: 1.5rem;
}

.TurnAroundMetric span {
  font-weight: bold;
}

.TurnAroundMetric h3 {
  font-weight: bold;
  margin-bottom: 4px;
}

@media (min-width: 375px) and (max-width: 576px) {
  .TurnAroundMetric {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .TurnAroundMetric {
    width: 65%;
  }
}

.AverageARMetric {
  width: 45%;
  margin-right: 1.5rem;
}

.AverageARMetric span {
  font-weight: bold;
}

.AverageARMetric h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AverageARMetric tbody tr:first-child {
  font-weight: bold;
}

tbody tr th {
  text-align: center;
}

@media (min-width: 375px) and (max-width: 576px) {
  .AverageARMetric {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .AverageARMetric {
    width: 65%;
  }
}

.AverageYOYChange {
  width: 40%;
}

.AverageYOYChange span {
  font-weight: bold;
}

.AverageYOYChange h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AverageYOYChange tbody tr:first-child {
  font-weight: bold;
}

tbody tr th {
  text-align: center;
}

@media (min-width: 375px) and (max-width: 576px) {
  .AverageYOYChange {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .AverageYOYChange {
    width: 65%;
  }
}



.DarkTheme {
    background-color: #005c95 !important;
    border-radius: 0 !important;
    width: 12rem !important;
    background: hsl(0,0%,100%);
    border: #005c95 !important;
    height: 2.7rem!important;
    margin:0!important;
    
}
.mr-lf
{
    margin-left:.15rem!important;
}
.mr-0
{
    margin:0!important;
}
Button:focus
{
    box-shadow: 0 0 0 3px #005c95;
    outline: none;
}
.LightTheme
{
    background-color:#69add8 !important;
    border: solid #69add8 1px !important;
    border-radius: 0 !important;
    width: 12rem !important;
    background: hsl(0,0%,100%);
    margin:0!important;
}
.rdt_TableHeadRow
{
    background-color: #005c95 !important;
}
#column-agencyName div,
#column-agencyName span
{
    color: #fff !important;
}
.EProTimeLine
{
    margin: 2rem 0rem;
}
.AverageCPSolicitationTime {
  margin-bottom: 2rem;
}

.AverageCPSolicitationTime h3,
.FinancialYear {
  text-align: left;
  margin-bottom: 1.5rem;
}

table th {
  color: #ffffff;
  background-color: #005c95;
  border-color: #ffffff;
}

tbody tr td {
  text-align: right;
}

table td,
th {
  border-color: #bfd9e9;
}

.SubHeading {
  font-weight: 400;
}

.CashReporting {
  margin-bottom: 2rem;
}

.CashReporting h3,
.CashReporting span,
.CashReporting ul {
  text-align: left;
}

.CashReporting > div > h3 {
  margin-bottom: 0.25rem;
}

.FundsTab > div > h3 {
  text-align: left;
}

.LiabilityChart .rv-xy-plot__axis__title text {
  translate: -0.25rem -4.5rem;
}

.Budget {
  margin-bottom: 1rem;
  margin-top:1rem;
}

.Budget h3 {
  text-align: left;
}

.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #464646;
}
.GamingHeading {
  margin-top:2rem;
 
}

.Gaming {
  margin-bottom: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #d1d1d1;
}

td,
th {
  border: 1px solid #464646;
  padding: 0.5rem;
}

.rv-xy-plot__axis__line {
  display: none;
}

.TotalFeesChart {
  margin-bottom: 1rem;
}

.TotalFeesChart h3,
.FeesByTribe h3 {
  text-align: left;
}

.AlignLeft {
  text-align: left;
}

.AlignRight {
  text-align: right;
}

.GreenColor {
  color: green;
}

.RedColor {
  color: red;
}

.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.WorkByProperty h3,
.WorkByCode h3,
.WorkByStatus h3,
.WorkByStatus section,
.WorkByDuration h3,
table tr td {
  text-align: left;
}

.WorkByDuration span {
  text-align: left;
  /* margin-bottom: 1rem; */
}

.GraphTab {
  margin-bottom: 2rem;
}

.AlignRight {
  text-align: right;
}

.MaintenanceTab {
  margin-bottom: 2rem;
}

.ConstructionTab {
  margin-bottom: 2rem;
}

.ConstructionTab h3 {
  text-align: left;
}

.rdt_TableCol {
  display: flex;
  justify-content: center;
}

.rdt_TableCol div {
  text-align: center;
}

.CAPPieChart div {
  text-align: left;
}

.CAPPieChart > h3{
  margin-bottom: 0.5rem;
}

.ChartSubTitle {
  text-align: left;
}

.SelectProjectType {
  margin-bottom: 1rem;
  text-align: left;
}
.ITAssetWrap > h3 {
  margin-bottom: -0.5rem;
}

.ITAssetManagement {
  margin-bottom: 2rem;
}

.ProductStatus,
.InboundPO {
  margin-bottom: 1rem;
}

.ProductStatus h2,
.InboundPO,
.FulfillmentRate {
  text-align: left;
}
.Title-Left h3 {
  text-align: left;
}
.Title-Left {
  margin-bottom: 1rem;
}

.MaintenanceNeeds > div > h3,
.PriorityInfo h3,
.PriorityInfo ol {
  text-align: left;
}

.HumanCapital h3 {
  text-align: left;
}

.OpenJob {
  margin-bottom: 1.5rem;
}

.OpenJob h3 {
  text-align: left;
}

.rdt_TableHeadRow div {
  background-color: #005c95;
  color: #ffffff;
}

.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.SubHeading {
  font-size: 1rem;
}

.PayrollChart h3,
.PayrollReversalOverTime h3 {
  text-align: left;
}

.PayrollWarrantsProcessed {
  margin-bottom: 2rem;
}

.PayrollReversalOverTime {
  min-height: 22.5rem !important;
}

.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.LPAGraph,
.TabsMetric {
  margin-bottom: 2rem;
}

.LPAGraph button {
  width: 8.5rem;
}

.GovDelivery {
  min-height: 25rem;
}

.GovDelivery .rv-xy-plot {
  min-height: 16rem;
}

.GovDelivery > div > span {
  font-weight: bold;
}

.PlatformTrends {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0.75rem;
}

.MediaStories .rv-xy-plot {
  min-height: 15.5rem;
}

.MediaStories .rv-xy-plot__inner {
  min-height: 16rem;
}

.MediaStories .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}

.OMESContents {
  margin-bottom: 2rem;
}

.UpArrow path {
  fill: green !important;
}

.DownArrow path {
  fill: red !important;
}

.URLLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TechnologyStandards {
  margin-top: 2rem;
}

.TechnologyStandards > div {
  text-align: left;
}

.TechnologyStandards > div > h3 {
  margin-bottom: 0.5rem;
}

.TechnologyStandards > div > span {
  margin-bottom: 1rem;
}

.CrossPlatform {
  margin-top: 2.7rem;
}

.CrossPlatform h3 {
  text-align: left;
}

.CrossPlatform > div > div {
  margin: auto;
}

.SocialMediaAccount h3,
.SocialMediaAccount span {
  text-align: left;
}

.SocialMediaAccount > div > span {
  margin-bottom: 1rem;
}

.MediaGrid {
  display: grid;
  grid-template-columns: auto auto;
}

.MediaGridItem {
  padding: 20px;
  text-align: center;
}

.MediaGridItem:nth-child(1) {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.MediaGridItem:nth-child(2) {
  border-bottom: 1px solid grey;
}

.MediaGridItem:nth-child(3) {
  border-right: 1px solid grey;
}

.UpArrow path {
  fill: green !important;
}

.DownArrow path {
  fill: red !important;
}

.PastMonths {
  margin-top: 1rem;
}

.RecentMonth {
  margin-top: 0.5rem;
}

.Black {
  color: #000;
}

.Green {
  color: green;
}

.Red {
  color: red;
}

.AccountsPayable {
  margin-top: 2rem;
}

.AccountsPayableHeader {
  display: grid;
  grid-template-columns: 1fr auto 2fr;
}

.KeyNotes {
  text-align: left;
  margin-left: 1.5rem;
}

.HorizontalDivider {
  width: 50%;
  border-color: #d1d1d1;
  height: 7;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.AccountsPayable > div > h3,
.AccountsPayable > div > span {
  text-align: left;
}

.AccountsPayable > div > h3 {
  margin-bottom: 0.25rem;
}
.ISCompliance div {
  text-align: left;
}

.ISCompliance h3 {
  margin-bottom: 0.5rem;
}
.last-approved
{
  float: left;
  margin-top: 1rem;
}
.expired-date
{
  float: right;
  margin-top: 1rem;
}
.font-size-2rem
{
  font-size: 1.5rem!important;
}


.SoleSource {
  margin-bottom: 2rem;
}

.SoleSource button {
  min-width: 12rem;
}

.SoleSourcePO > div,
.SoleSourcePOSpend > div {
  text-align: left;
}

.SoleSourcePO > div > h3,
.SoleSourcePOSpend > div > h3 {
  margin-bottom: 0.5rem;
}

.button-align--right {
  float: right;
}

.HeaderInfo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.HeaderInfo H1 {
  margin: 0;
  text-align: center;
}
.DirectPOTrendline {
  margin-bottom: 2rem;
}

.DirectPO > div,
.DirectPOSpend > div {
  text-align: left;
}

.DirectPO > div > h3,
.DirectPOSpend > div > h3 {
  margin-bottom: 0.5rem;
}

.POHeader {
  display: grid;
  grid-template-columns: 4fr 1fr 15fr;
  margin: 2rem 4rem;
}

.HeaderChart .rv-xy-plot__inner {
  min-height: 16rem;
}

.HeaderInfo {
  align-self: center;
}

.PODivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 14rem;
  margin-top: 1rem;
}

.button-align--right {
  float: right;
}
.OpenRecords,
.Compliance,
.InsuranceSurvey {
  margin-bottom: 2rem;
}

.SelectAssessmentAgency {
  margin-bottom: 1rem;
}

.YearValue {
  font-size: 0.82rem;
  margin-top: 1rem;
}

.ProjectsReviewed,
.CAPProjectsReviewed {
  margin-bottom: 2rem;
}

.CAPCommonTabs div h3 {
  text-align: left;
}

.WeeklyNonTravel {
  margin-bottom: 2rem;
}

.TPCount div,
.TPAmountPaid > div {
  text-align: left;
}

.TPCount > div > h3,
.TPAmountPaid > div > h3 {
  margin-bottom: 0.5rem;
}

.NonTravelInfo {
  margin-top: 2rem;
}

.TravelDivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 4rem;
  margin-top: 1rem;
}

.HigherEducation {
  margin-bottom: 2rem;
}

.HigherEd > div {
  text-align: left;
}

.HigherEd > div > h3 {
  margin-bottom: 0.5rem;
}

.HigherEdInfo {
  margin-top: 2rem;
}

