.DisasterRecovery {
  margin-top: 2rem;
}

.DisasterRecovery h3 {
  font-size: 1.25rem;
}

.DisasterRecovery h3,
.StaticText {
  text-align: left;
}
