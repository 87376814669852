.ITAssetWrap > h3 {
  margin-bottom: -0.5rem;
}

.ITAssetManagement {
  margin-bottom: 2rem;
}

.ProductStatus,
.InboundPO {
  margin-bottom: 1rem;
}

.ProductStatus h2,
.InboundPO,
.FulfillmentRate {
  text-align: left;
}
.Title-Left h3 {
  text-align: left;
}
.Title-Left {
  margin-bottom: 1rem;
}
