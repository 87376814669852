.OMESContents {
  margin-bottom: 2rem;
}

.UpArrow path {
  fill: green !important;
}

.DownArrow path {
  fill: red !important;
}

.URLLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
