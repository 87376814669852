.OMES-At-Glance {
  margin-top: -2rem;
  float: left;
}

.No-Data-Message {
  float: left;
}

.Total-Value {
  background-color: #005c95 !important;
  color: #ffffff !important;
}

.AdjustHeight {
  display: grid;
}

.Light-Blue {
  background: #e6f0f6;
  color: #003251;
}

.rv-crosshair__item {
  display: none;
}

.ShowHint {
  background: #3a3a48;
  border-radius: 4px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  width: max-content;
}

.TopHeader {
  display: flex;
  justify-content: space-around;
}

.HeadCounts {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
}
.non-complaince-counts {
  /* display: flex;
  flex-direction: column; */
  /* margin-bottom: 1.5rem; */
}

.Divider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  height: 7rem;
  margin-top: 1rem;
}

.HintWrap {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.Dark-Theme .fa-secondary, .Dark-Theme .fa-primary {
  
  fill:#005c95 !important;
  opacity :revert;
  cursor: pointer !important;
}
.Main-Title
{
  float: left;
  margin-top: -2rem;
}
.Sub-Title
{
  float: left !important;
    text-align: left;
    margin-top: 0rem;
    clear:left;
}
.title-wrapper
{
  color:#005c95;
  padding-bottom: 0.5rem;
}
.title {
  margin: 1rem 0 0.5rem;
}
.Margin-Top-3
{
  margin-top: -3rem
}
.Banner-Style
{
  color: #000;
    /* background-color: rgb(168, 107, 28); */
    background-color: #F4E2D9;
    height: 2rem;
    font-style: italic;
    text-align: center;
    vertical-align: middle;
    line-height: 2rem;
}
.Banner-Style a{
  /* color: #fff !important; */
}
.Card-Margin-Top
{
  margin-top:.75rem;
}
.No-Color
{    border: none!important;
  background-color: #fff!important;
}
.Center-align{
  text-align: center;
}
.Small-Divider
{
  height: 3rem!important;
    margin-bottom: 1.5rem;
    margin-top: 0rem;
}
.metricDivider {
  content: "";
  display: block;
  width: 2px;
  background: #d1d1d1;
  margin-top: 1rem;
}
.TopHeader-Tasks
{
  display: flex;
    justify-content: center;
}
.Card-Margin-Top h3{
  text-align: left;
}
.Horizontal-Divider {
  border-top: 2px solid #464646;
  margin: 2rem;
}
.Margin-Top-2rem
{
  margin-top: 2rem;
}
.Day-Subtitle
{
float:left;
margin-top:-1rem;
margin-bottom: 2rem;
}

.alt-y-label {
  transform: translate(20px, 25px);
}

.wrap-text {
  white-space: pre-wrap;
}

.justify-content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin-bottom-xs {
  margin-bottom: 0.5rem;
}

.card-wrapper h3 {
  text-align: left;
}

.footer-text {
  font-weight: 700;
  line-height: 1.5;
  margin-top: 1rem;
}
.no-bullets, .no-bullets li {
  list-style-type:none;

}