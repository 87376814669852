.DarkTheme {
    background-color: #005c95 !important;
    border-radius: 0 !important;
    width: 12rem !important;
    background: hsl(0,0%,100%);
    border: #005c95 !important;
    height: 2.7rem!important;
    margin:0!important;
    
}
.mr-lf
{
    margin-left:.15rem!important;
}
.mr-0
{
    margin:0!important;
}
Button:focus
{
    box-shadow: 0 0 0 3px #005c95;
    outline: none;
}
.LightTheme
{
    background-color:#69add8 !important;
    border: solid #69add8 1px !important;
    border-radius: 0 !important;
    width: 12rem !important;
    background: hsl(0,0%,100%);
    margin:0!important;
}
.rdt_TableHeadRow
{
    background-color: #005c95 !important;
}
#column-agencyName div,
#column-agencyName span
{
    color: #fff !important;
}
.EProTimeLine
{
    margin: 2rem 0rem;
}