.ConstructionTab {
  margin-bottom: 2rem;
}

.ConstructionTab h3 {
  text-align: left;
}

.rdt_TableCol {
  display: flex;
  justify-content: center;
}

.rdt_TableCol div {
  text-align: center;
}

.CAPPieChart div {
  text-align: left;
}

.CAPPieChart > h3{
  margin-bottom: 0.5rem;
}

.ChartSubTitle {
  text-align: left;
}

.SelectProjectType {
  margin-bottom: 1rem;
  text-align: left;
}