.CrossPlatform {
  margin-top: 2.7rem;
}

.CrossPlatform h3 {
  text-align: left;
}

.CrossPlatform > div > div {
  margin: auto;
}
