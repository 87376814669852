.AzureCost h3 {
  text-align: left;
}

.AzureCost .rv-xy-plot {
  min-height: 13rem;
}

.AzureCost .rv-xy-plot__inner {
  min-height: 14rem;
}

.AzureCost .rv-xy-plot__axis__title text {
  translate: -3.5rem -4.5rem;
}
