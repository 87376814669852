.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #464646;
}
.GamingHeading {
  margin-top:2rem;
 
}

.Gaming {
  margin-bottom: 2rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #d1d1d1;
}

td,
th {
  border: 1px solid #464646;
  padding: 0.5rem;
}

.rv-xy-plot__axis__line {
  display: none;
}

.TotalFeesChart {
  margin-bottom: 1rem;
}

.TotalFeesChart h3,
.FeesByTribe h3 {
  text-align: left;
}

.AlignLeft {
  text-align: left;
}

.AlignRight {
  text-align: right;
}

.GreenColor {
  color: green;
}

.RedColor {
  color: red;
}
