.MonthlyUniqueVisitsCard{
    max-width: 300px;
    min-height: 210px;
    background-color: #2D5C6C ;
    margin-bottom: 30px;
    padding: 20px 10px 30px 30px;
    border-radius: 1px;
    position: relative;
}
.MonthlyUniqueVisitsCard > span {
    top: 0.5rem;
}
.SwvNumberCard{
    background-color: #ffffff;
    width: 240px;
    min-height: 120px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
}
.SwvTitle{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  color: #FFFFFF;
  padding-bottom: 20px;
  margin-left: 20px;
}
.SwvNumber{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 36px;
   margin-top: 18px;
   margin-left: 30px;
}
.SwvNumberBlock{
    display: flex;
}
.fas{
   margin-top: 25px;
   margin-left: 5px;
}