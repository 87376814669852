.AverageARMetric {
  width: 45%;
  margin-right: 1.5rem;
}

.AverageARMetric span {
  font-weight: bold;
}

.AverageARMetric h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AverageARMetric tbody tr:first-child {
  font-weight: bold;
}

tbody tr th {
  text-align: center;
}

@media (min-width: 375px) and (max-width: 576px) {
  .AverageARMetric {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .AverageARMetric {
    width: 65%;
  }
}

.AverageYOYChange {
  width: 40%;
}

.AverageYOYChange span {
  font-weight: bold;
}

.AverageYOYChange h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AverageYOYChange tbody tr:first-child {
  font-weight: bold;
}

tbody tr th {
  text-align: center;
}

@media (min-width: 375px) and (max-width: 576px) {
  .AverageYOYChange {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .AverageYOYChange {
    width: 65%;
  }
}

