.HigherEducation {
  margin-bottom: 2rem;
}

.HigherEd > div {
  text-align: left;
}

.HigherEd > div > h3 {
  margin-bottom: 0.5rem;
}

.HigherEdInfo {
  margin-top: 2rem;
}
