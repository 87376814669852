.AccountsPayable {
  margin-top: 2rem;
}

.AccountsPayableHeader {
  display: grid;
  grid-template-columns: 1fr auto 2fr;
}

.KeyNotes {
  text-align: left;
  margin-left: 1.5rem;
}

.HorizontalDivider {
  width: 50%;
  border-color: #d1d1d1;
  height: 7;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.AccountsPayable > div > h3,
.AccountsPayable > div > span {
  text-align: left;
}

.AccountsPayable > div > h3 {
  margin-bottom: 0.25rem;
}