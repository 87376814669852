.OpenRecords,
.Compliance,
.InsuranceSurvey {
  margin-bottom: 2rem;
}

.SelectAssessmentAgency {
  margin-bottom: 1rem;
}

.YearValue {
  font-size: 0.82rem;
  margin-top: 1rem;
}
