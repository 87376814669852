.Heading {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #464646;
}

.SubHeading {
  font-size: 1rem;
}

.PayrollChart h3,
.PayrollReversalOverTime h3 {
  text-align: left;
}

.PayrollWarrantsProcessed {
  margin-bottom: 2rem;
}

.PayrollReversalOverTime {
  min-height: 22.5rem !important;
}
