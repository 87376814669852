.TurnAroundMetric {
  width: 40%;
  margin-right: 1.5rem;
}

.TurnAroundMetric span {
  font-weight: bold;
}

.TurnAroundMetric h3 {
  font-weight: bold;
  margin-bottom: 4px;
}

@media (min-width: 375px) and (max-width: 576px) {
  .TurnAroundMetric {
    width: 95%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .TurnAroundMetric {
    width: 65%;
  }
}
