.ProvisioningResolutionTime {
  margin: 0.75rem 0rem;
}

.ProvisioningResolutionTime button {
  min-width: 15rem;
}

.ActualResTime div {
  text-align: left;
}

.ActualResTime div h3 {
  margin-bottom: 0.5rem;
}

.ActualChart {
  margin-top: 1rem;
}

.MonthlyChart .rv-xy-plot {
  min-height: 13rem;
}

.MonthlyChart .rv-xy-plot__inner {
  min-height: 14rem;
}

.MonthlyChart .rv-xy-plot__axis__title text {
  translate: -1rem -4.5rem;
}
