.OfferedTraining h3 {
  text-align: left;
}

.TrainingChart {
  display: flex;
  align-items: center;
}

.TotalHours {
  display: flex;
  /* margin-left: 2rem; */
  justify-content: center;
}

.SLSTotalHours {
  margin: 1rem 5rem;
}

.ODCSTotalHours {
  /* margin: 1rem 3rem; */
  margin-left: 2rem;
}

.SLSHours,
.ODCSHours {
  text-align: center;
  margin-top: 0.2rem;
}
.SmallTabs
{
  width: 7rem!important;
}
